import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';

import { EditorModule } from '@tinymce/tinymce-angular';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { TableListComponent } from './table-list/table-list.component';
import { TypographyComponent } from './typography/typography.component';
import { IconsComponent } from './icons/icons.component';
import { MapsComponent } from './maps/maps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { UpgradeComponent } from './upgrade/upgrade.component';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { KurumsalComponent } from './Sayfalar/kurumsal/kurumsal.component';
import { UrunlerComponent } from './Sayfalar/urunler/urunler.component';
import { UretimComponent } from './Sayfalar/uretim/uretim.component';
import { ReferanslarComponent } from './Sayfalar/referanslar/referanslar.component';
import { IletisimComponent } from './Sayfalar/iletisim/iletisim.component';

import {DragDropModule} from '@angular/cdk/drag-drop';


import {
    MatButtonModule,
    MatInputModule,
    MatRippleModule,
    MatTooltipModule,
    MatSelectModule,
    MatTabsModule,
    MatSliderModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule, MAT_DATE_LOCALE,
} from '@angular/material';

import {HttpClientModule} from '@angular/common/http';
import { MakineparkuruComponent } from './Sayfalar/makineparkuru/makineparkuru.component';
import { AyarlarComponent } from './Sayfalar/ayarlar/ayarlar.component';
import { FaaliyetAlanlariComponent } from './Sayfalar/faaliyet-alanlari/faaliyet-alanlari.component';
import { AnaSayfaBannerComponent } from './Sayfalar/ana-sayfa-banner/ana-sayfa-banner.component';
import { HaberlerComponent } from './Sayfalar/haberler/haberler.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    MatButtonModule,
    MatRippleModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    MatPaginatorModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSliderModule,
    MatTabsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    EditorModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    KurumsalComponent,
    UrunlerComponent,
    UretimComponent,
    ReferanslarComponent,
    IletisimComponent,
    MakineparkuruComponent,
    AyarlarComponent,
    FaaliyetAlanlariComponent,
    AnaSayfaBannerComponent,
    HaberlerComponent,

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

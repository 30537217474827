import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import {UrunlerComponent} from './Sayfalar/urunler/urunler.component';
import {KurumsalComponent} from './Sayfalar/kurumsal/kurumsal.component';
import {UretimComponent} from './Sayfalar/uretim/uretim.component';
import {ReferanslarComponent} from './Sayfalar/referanslar/referanslar.component';
import {IletisimComponent} from './Sayfalar/iletisim/iletisim.component';
import {MakineparkuruComponent} from './Sayfalar/makineparkuru/makineparkuru.component';
import {AyarlarComponent} from './Sayfalar/ayarlar/ayarlar.component';
import {FaaliyetAlanlariComponent} from './Sayfalar/faaliyet-alanlari/faaliyet-alanlari.component';
import {AnaSayfaBannerComponent} from './Sayfalar/ana-sayfa-banner/ana-sayfa-banner.component';
import {HaberlerComponent} from './Sayfalar/haberler/haberler.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'urunler',
        pathMatch: 'full',
    },
    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'urunler',
                component: UrunlerComponent ,
                pathMatch: 'full',
            },
            {
                path: 'kurumsal',
                component: KurumsalComponent ,
                pathMatch: 'full',
            },
            {
                path: 'uretim',
                component: UretimComponent ,
                pathMatch: 'full',
            },
            {
                path: 'referanslar',
                component: ReferanslarComponent ,
                pathMatch: 'full',
            },
            {
                path: 'faaliyet-alanlari',
                component: FaaliyetAlanlariComponent ,
                pathMatch: 'full',
            },
            {
                path: 'iletisim',
                component: IletisimComponent ,
                pathMatch: 'full',
            },
            {
                path: 'makinaparkuru',
                component: MakineparkuruComponent ,
                pathMatch: 'full',
            },
            {
                path: 'blog',
                component: HaberlerComponent ,
                pathMatch: 'full',
            },
            {
                path: 'banner',
                component: AnaSayfaBannerComponent ,
                pathMatch: 'full',
            },
            {
                path: 'settings',
                component: AyarlarComponent ,
                pathMatch: 'full',
            },
        ]
    }
    // { path: 'dashboard',      component: DashboardComponent },
    // { path: 'user-profile',   component: UserProfileComponent },
    // { path: 'table-list',     component: TableListComponent },
    // { path: 'typography',     component: TypographyComponent },
    // { path: 'icons',          component: IconsComponent },
    // { path: 'maps',           component: MapsComponent },
    // { path: 'notifications',  component: NotificationsComponent },
    // { path: 'upgrade',        component: UpgradeComponent },
    // { path: '',               redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DataServisiService} from '../../data-servisi.service';


@Component({
  selector: 'app-haberler',
  templateUrl: './haberler.component.html',
  styleUrls: ['./haberler.component.scss']
})
export class HaberlerComponent implements OnInit {

    aktif_tablo = 'blogs';
    @ViewChild('resimalani') resimalani: ElementRef;

    girilmemisalanlar = [];
    girilmemis_alan_var = false;
    kategoriler_index = {};

    public liste_data = [];
    public liste_sayi = 0;

    public aktif_mod = '';

    eklemetur = '';
    contenttype = '';

    public duzenleme_sayfa_sayfa_sayisi  = 0;

    public duzenleme_sayfa_baslangic     = 0;
    public duzenleme_sayfa_kacarli       = 10;
    public duzenleme_sayfa_aktif_sayfa  = 0;
    public duzenleme_data: any = {};
    public duzenlenen_id: any = undefined;
    public eklenece_youtube_linki: any = '';

    video_linki_ekle() {
        this.duzenleme_data.videolar = this.duzenleme_data.videolar || [];
        this.duzenleme_data.videolar.push({
            tur: 'video',
            dosya: this.eklenece_youtube_linki,
        });
        this.eklenece_youtube_linki = '';
    }
    mesajlar_sayfa_degisti(event) {
        this.duzenleme_sayfa_kacarli = event.pageSize;
        this.duzenleme_sayfa_baslangic = event.pageIndex * this.duzenleme_sayfa_kacarli;
        console.log( 'mesajlar_sayfa_degisti' , event , this.duzenleme_sayfa_aktif_sayfa )
    }
    public ekle_duzenle_iptal() {
        this.duzenleme_data = {
            dil: 'en',
        };
        this.aktif_mod = '';
        this.duzenlenen_id = undefined;
    }


    public async resim_secildi(event, item, type) {

        console.log( 'resim_secildi' , event);

        let secilen_resim = event.target.files || [];
        if (secilen_resim.length === 0) {
            this.resimalani.nativeElement.value = '';
            return;
        }

        secilen_resim = secilen_resim[0];

        const formData = new FormData();
        formData.append('file', secilen_resim );

        const options = {
            method: 'POST',
            body: formData,
        };

        console.log(secilen_resim);

        const sonuc        =  await fetch('/resim', options);
        const gelen_sonuc  =  await sonuc.json();

        console.log( 'gelen_sonuc' , gelen_sonuc );
        if (gelen_sonuc === undefined || gelen_sonuc === null || gelen_sonuc.ok !== 1) {
            this.resimalani.nativeElement.value = '';
            return;
        }

        console.log(sonuc);
        console.log(gelen_sonuc);

        this.duzenleme_data.media = this.duzenleme_data.media || [];
        this.duzenleme_data.media.push({
            dosya: gelen_sonuc.dosya,
            tur: type,
        });
        console.log( 'this.duzenleme_data.media' , this.duzenleme_data.media);

    }

    upload(blobInfo, success, failure) {
        console.log( 'upload' , blobInfo, success, failure );
    }

    public yeni_mesaj() {

        this.duzenleme_data.mesajlar = this.duzenleme_data.mesajlar || [];
        this.duzenleme_data.mesajlar.push({})

    }

    public yeni_cat_ekle() {
        this.duzenleme_data.cat = this.duzenleme_data.cat || [];
        this.duzenleme_data.cat.push(undefined);
    }

    public yeni_ekle() {
        this.duzenleme_data = {
            order: 100,
            dil: 'en',
        };
        this.aktif_mod = 'yeni';
    }

    public duzenle(item) {

        this.duzenleme_data = JSON.parse(JSON.stringify(item));
        this.duzenlenen_id  = this.duzenleme_data.id;
        this.aktif_mod      = 'duzenle';
    }

    public sil(item){

        if (confirm('Bu kayıt silinsin mi?')) {

            this.servis.getgetir('/sil/' + this.aktif_tablo  + '/' + item.id, (sonuc) => {

                console.log('yenile' , this.aktif_tablo , sonuc);

                setTimeout(() => {

                    this.yenile();

                }, 1001)

            })

            // this.duzenleme_data = JSON.parse(JSON.stringify(item));
            // this.duzenlenen_id  = this.duzenleme_data.id;
            // this.aktif_mod      = 'duzenle';
        }

    }


    public kaydet() {

        console.log('kaydet' , this.aktif_tablo );

        let zorunlu_alanlar = [
            'isim',
            // 'aciklama',
            // 'body',
            // 'content_type',
            // 'order',
            // 'ulke_kodu',
            // 'tarih',
            // 'media',
        ];

        this.girilmemis_alan_var = false;

        const girilmemisalanlar = [];
        for (let i = 0; i < zorunlu_alanlar.length; i++) {
            if( this.duzenleme_data[zorunlu_alanlar[i]]  === undefined || this.duzenleme_data[zorunlu_alanlar[i]] === '' ) {
                this.girilmemis_alan_var = true;
                girilmemisalanlar.push(zorunlu_alanlar[i])
            }
        }

        if (this.girilmemis_alan_var === true) {
            this.girilmemisalanlar = girilmemisalanlar;
            return;
        }

        this.servis.postgetir('/yaz/' + this.aktif_tablo , {
            ne: this.duzenleme_data
        }, (data) => {
            if (data !== undefined && data !== null) {

                if (data.hata_yok === true) {
                    this.duzenleme_data = {};
                    this.girilmemis_alan_var = false;
                    this.aktif_mod = '';
                } else {
                    alert('Kayıt eklenemedi');
                }

                setTimeout(()=>{
                    this.yenile();
                }, 1001)

            }
            console.log('kaydet sonuç' , data );
        })

    }


    yenile() {

        this.servis.getgetir('/getir/' + this.aktif_tablo , (sonuc) => {

            console.log('yenile' , this.aktif_tablo , sonuc);

            if (sonuc !== undefined && sonuc !== null) {
                this.liste_data = sonuc.sonuc || [];
                this.liste_data = this.liste_data.sort(( x , y ) => {
                    if (x.id > y.id) return 1;
                    if (x.id < y.id) return -1;
                    return 0;
                });

                for (let i = 0; i < this.liste_data.length; i++) {
                    const listeDatum = this.liste_data[i];
                    this.kategoriler_index[listeDatum.id] = listeDatum;
                }
                this.liste_sayi = sonuc.sayi  || [];
                this.duzenlenen_id = undefined;
            }

        })

    }

    constructor(public servis: DataServisiService) { }

    ngOnInit() {

        this.yenile();

    }

}

import { Component} from '@angular/core';
import {DataServisiService} from './data-servisi.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(public servis: DataServisiService) {
  }
    sifre_kontrolu() {
        this.servis.sifre_kontrolu( )
    }
}

import { Component, OnInit } from '@angular/core';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/kurumsal', title: 'Kurumsal',  icon: 'dashboard', class: '' },
    { path: '/faaliyet-alanlari', title: 'Faaliyet Alanları',  icon: 'dashboard', class: '' },
    { path: '/urunler', title: 'Ürünler',  icon: 'dashboard', class: '' },
    { path: '/uretim', title: 'Üretim',  icon: 'dashboard', class: '' },
    { path: '/makinaparkuru', title: 'Makina Parkuru',  icon: 'dashboard', class: '' },
    { path: '/referanslar', title: 'Referanslar',  icon: 'dashboard', class: '' },
    { path: '/iletisim', title: 'İletişim',  icon: 'dashboard', class: '' },
    { path: '/blog', title: 'Haberler',  icon: 'dashboard', class: '' },
    { path: '/banner', title: 'Slider',  icon: 'dashboard', class: '' },
    { path: '/settings', title: 'Ayarlar',  icon: 'dashboard', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor() { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
